import Vue from 'vue'
import VueRouter from 'vue-router'
import { firebaseApp } from '../firebaseDb'
import NProgress from 'nprogress'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../components/Dashboard'),
    meta: {
      auth: true,
      title: "Übersicht"
    }
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('../components/ListSB'),
    meta: {
      auth: true,
      title: "Einweisungen"
    }
  },
  {
    path: '/new/:parcours/:attendees/:docID/:trainer/:blockDuration/:briefingID',
    name: 'new',
    component: () => import('../components/AddSB'),
    meta: {
      auth: true,
      title: "Einweisungen speichern"
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/Login'),
    meta: {
      auth: false,
      title: "Anmeldung",
      hideNavigation: true
    }
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../components/Info'),
    meta: {
      auth: true,
      title: "Info"
    }
  },
  {
    path: '/settings-trainer',
    name: 'settings-trainer',
    component: () => import('../components/Settings-Trainer'),
    meta: {
      auth: true,
      title: "Einstellungen - Trainer"
    }
  },
  {
    path: '/settings-parcours',
    name: 'settings-parcours',
    component: () => import('../components/Settings-Parcours'),
    meta: {
      auth: true,
      title: "Einstellungen - Parcours"
    }
  },
  {
    path: '/settings-password',
    name: 'settings-password',
    component: () => import('../components/Settings-Password'),
    meta: {
      auth: true,
      title: "Einstellungen - Passwort"
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route spinner.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route spinner.
  NProgress.done()
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.auth && !await firebaseApp.getCurrentUser()) {
      router.push({ path: 'login' })
  } else {
      document.title = to.meta.title + ' - EinweisungsApp'
      next()
  }
})

export default router
