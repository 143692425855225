import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null
    },
    appVersion: ''
  },
  getters: {
    user (state) {
      return state.user
    },
    appVersion (state) {
      return state.appVersion
    }
  },
  mutations: {
    SET_LOGGED_IN (state, value) {
      state.user.loggedIn = value
    },
    SET_USER (state, data) {
      state.user.data = data
    },
    SET_APPVERSION (state, value) {
      state.appVersion = value
    }
  },
  actions: {
    fetchUser ({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null)
      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          uid: user.uid
        })
      } else {
        commit("SET_USER", null)
      }
    }
  }
})

export default store
