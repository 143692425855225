import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import store from "./store"
import { firebaseApp } from './firebaseDb'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import '../node_modules/nprogress/nprogress.css'
import { version } from '../package'

const toastOptions = {
  // https://github.com/Maronato/vue-toastification/tree/master
}
Vue.use(Toast, toastOptions)

// import 'bootstrap/dist/css/bootstrap.min.css'
Vue.use(Datetime)
Vue.config.productionTip = false
store.commit('SET_APPVERSION', version)

firebaseApp.auth().onAuthStateChanged(function (user) {
  console.log(user)
  store.dispatch("fetchUser", user)
  new Vue({
      router,
      render: h => h(App),
      created () {
        // get time offset in milliseconds and store it in local storage
        var offsetRef = firebaseApp.database().ref(".info/serverTimeOffset")
        offsetRef.on("value", (snap) => {
          var offset = snap.val()
          console.log('Save time offset: ' + offset)
          localStorage.timeOffset = offset
        })
      }
    }).$mount('#app')
  }
)
