<template>
  <div class="d-flex flex-column min-vh-100">
    <nav v-if="!$route.meta.hideNavigation" class="navbar navbar-expand-lg navbar-light bg-light sticky-top" style="border-bottom:3px solid #d32324;">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src="./assets/logo.png" width="70" alt="Logo Kletterwald">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarContent">
          <ul class="nav navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link pr-3" to="/" data-toggle="collapse" data-target=".navbar-collapse.show"><i class="bi bi-columns"></i> Übersicht</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link pr-3" to="/list" data-toggle="collapse" data-target=".navbar-collapse.show"><i class="bi bi-journal-medical"></i> Einweisungen</router-link>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-gear"></i> Einstellungen
              </a>
                <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                  <li><router-link class="nav-link dropdown-item" to="/settings-trainer" data-toggle="collapse" data-target=".navbar-collapse.show"><i class="bi bi-person-bounding-box"></i> Trainer</router-link></li>
                  <li><router-link class="nav-link dropdown-item" to="/settings-parcours" data-toggle="collapse" data-target=".navbar-collapse.show"><i class="bi bi-ladder"></i> Parcours</router-link></li>
                  <li><router-link class="nav-link dropdown-item" to="/settings-password" data-toggle="collapse" data-target=".navbar-collapse.show"><i class="bi bi-key"></i> Passwort ändern</router-link></li>
                </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click.prevent="signOut" style="cursor: pointer;"><i class="bi bi-box-arrow-right"></i> Abmelden</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="container-lg mt-5">
      <router-view></router-view>
    </div>
    <!-- Footer -->
            <footer v-if="!$route.meta.hideNavigation" class="footer mt-auto bg-light d-print-none">
              <div class="container">
                    <!-- Copyright Info -->
                        <div class="flex-row float-left small">
                            Version {{ version }}
                        </div>
                        <div class="flex-row float-right small">
                            &copy; {{ year }} Mario Lauterbach <router-link class="" to="/info"><i class="bi bi-question-circle-fill"></i></router-link>
                        </div>
                    <!-- END Copyright Info -->
               </div>
            </footer>
  </div>
</template>

<style lang="scss">
@import './assets/styles/custom.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
</style>

<script>
import { firebaseApp } from './firebaseDb'
import store from "./store"
export default {
  data () {
    return {
        year: new Date().getFullYear(),
        version: store.getters.appVersion
    }
  },
  methods: {
    signOut () {
      firebaseApp
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "login"
          })
        })
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.navbar {
  padding-left: 0.2rem !important;
  padding-right: 0.2rem !important;
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.9);
}
.footer {
  height: 50px;
  line-height: 50px;
}
</style>
