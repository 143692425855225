import firebase from 'firebase'
import 'firebase/firestore'
// import * as firebase from "firebase"

const firebaseConfig = {
    apiKey: "AIzaSyAISd2u2Eqs5K7--2Xisq5qfls7nJd-07c",
    authDomain: "kletterwaldmanager.firebaseapp.com",
    databaseURL: "https://kletterwaldmanager.firebaseio.com",
    projectId: "kletterwaldmanager",
    storageBucket: "kletterwaldmanager.appspot.com",
    messagingSenderId: "414670234372",
    appId: "1:414670234372:web:15d87583623e724ac32651",
    measurementId: "G-HCVMH5TXXT"
}

export const firebaseApp = firebase.initializeApp(firebaseConfig)

firebaseApp.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebaseApp.auth().onAuthStateChanged(user => {
            unsubscribe()
            resolve(user)
        }, reject)
    })
}

export const auth = firebase.auth()

auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)

export const db = firebaseApp.firestore()

const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }
db.settings({ timestampsInSnapshots: true })
